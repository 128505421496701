<template>
  <div class="pb-16 other_pages">
    <v-container>
      <v-row class="my-8">
        <v-col class="text-center">
          <h1 class="text-h2 mb-8">
            About AirfieldHub
          </h1>
          <p class="text-h5">
            Our story and why we exist...
          </p>
        </v-col>
      </v-row>

      <section class="py-8">
        <v-row justify="center">
          <v-col
            cols="12"
            sm="10"
            md="8"
          >
            <v-card>
              <v-card-text>
                <p class="text-body-1">
                  AirfieldHub was born out of frustration with paper-based
                  logbooks and barely legible handwriting, carrying around cash
                  for landing fees, or dealing with slow and clunky old systems,
                  airfields and pilots wanted something better!
                </p>
                <p class="text-body-1">
                  Alex and Rob are both pilots and professional software developers who have partnered with
                  airfields around the UK to build a modern solution. AirfieldHub helps manage the airfields
                  log all aircraft movements, provide online PPR, Booking Out requests, take card payments, and overall make
                  their day to day airfield management much simpler than it was previously.
                </p>
                <p class="text-body-1">
                  With our background in building fantastic web applications and
                  websites, we started designing and building a simple yet
                  extremely powerful system that makes everyone's lives easier. We believe
                  software should remove unnecessary steps and be as simple as
                  possible to do what you need, allowing you to concentrate on the things that really matter to your business.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </section>
    </v-container>

    <div class="my-16">
      <v-container>
        <div class="d-flex flex-column justify-space-between align-center my-8">
          <v-btn
            x-large
            to="/register"
            color="homebuttonbg"
            class="homebuttoncolour--text"
          >
            <v-icon left>
              mdi-rocket-launch
            </v-icon>
            Sign up for free today
          </v-btn>
        </div>
      </v-container>
    </div>
    <section class="no-parallax shorter_content">
      <footer-bar />
    </section>
  </div>
</template>

<script>
import FooterBar from "../components/FooterBar.vue";

export default {
  name: "About",
    components: { FooterBar },

};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
}
</style>
